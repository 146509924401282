<template>
  <div class="main_container">
    <div class="col-12 bread-crumbs">
      <span>Plan comptable ></span>
    </div>
    <div class="row justify-content-center my-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Plan comptable
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <Notif :notif="notif" />
    </div>
    <div class="row">
      <button class="btn btn-blue pl-5 pr-5 op-btn"
              v-if="(user[1] == 'FER' && user[0] == 'DAF')||(user[1] == 'FER' && user[0] == 'ADMIN')"
              data-toggle="modal"
              ref="modal_button"
              data-target="#exampleModal"
              @click="av='plan'">Ajouter un n° comptable</button>
    </div>
    <div class="inner_container">
      <ul class="nav nav-tabs mt-4"
          id="myTab" 
          role="tablist">
        <li class="nav-item" 
            role="presentation">
          <a class="nav-link active"
             id="home-tab"
             data-toggle="tab"
             href="#home"
             role="tab"
             aria-controls="home"
             aria-selected="true"
             @click="choix='generale',reloadPlanCompte()">Liste des n° de compte </a>
        </li>
        <li class="nav-item" 
            role="presentation">
          <a class="nav-link"
             id="contact-tab"
             data-toggle="tab"
             href="#contact"
             role="tab"
             aria-controls="contact"
             aria-selected="true"
             @click="choix='situation'">Reporting</a>
        </li>
        <!-- <li class="nav-item"
            role="presentation">
          <a class="nav-link"
             id="profile-tab"
             data-toggle="tab"
             href="#profile"
             role="tab"
             aria-controls="profile"
             aria-selected="true"
             @click="choix='bilan'">Bilan</a>
        </li>
        <li class="nav-item"
            role="presentation">
          <a class="nav-link"
             id="address-tab"
             data-toggle="tab"
             href="#address"
             role="tab"
             aria-controls="address"
             aria-selected="true"
             @click="choix='resultat'">Compte Résultat</a>
        </li>   -->
      </ul>

      <div class="tab-content"
           id="myTabContent">
        <div class="tab-pane fade show active"
             id="home"
             role="tabpanel" 
             aria-labelledby="home-tab">
          <div class="row mt-3">
            <form class="form-inline mx-auto">
              <div class="form-group">
                <label for="">Base:</label>
                <select class="form-control ml-3" 
                        name="" 
                        id=""
                        v-model="base"
                        @change="changeBase()">
                  <option value="CONSOLIDE">Consolidé</option>
                  <option value="SIEGE">Siège</option>
                  <option value="PEAGE">Péage</option>
                  <option value="PESAGE">Pesage</option>
                  <option value="AIRE_STATIONNEMENT">Aire de stationnement</option>
                </select>
              </div>
            </form>
          </div>
          <!--Tableau plan  -->
          <div class="row mt-3"
               v-if="choix=='generale'">
            <table class="table tablestyle_3 table-striped">
              <thead>
                <tr>
                  <th class="th-blue">Références</th>
                  <th class="th-blue">Description</th>
                  <th class="th-blue">Base</th>
                  <th class="th-blue">Type</th>
                  <th class="th-blue">Catégorie</th>
                  <th class="th-blue">Action</th>
                </tr>
              </thead>
              <tbody v-if="long==true && longueur!=0">
                <tr v-for="(plan,pkey) in comptes"
                    :key="pkey">
                  <td scope="row">{{plan.compte}}</td>
                  <td >{{plan.description}}</td>
                  <td >{{plan.base}}</td>
                  <td >{{plan.flux}}</td>
                  <td >{{plan.categorie}}</td>
                  <td class="icon-blue text-right">
                    <i class="flaticon-pencil"  
                       v-if="(user[1] == 'FER' && (user[0] == 'DAF'|| user[0]=='DCG'))||(user[1] == 'FER' && user[0] == 'ADMIN')"
                       @click="updateForm(plan),av='modifplan'"
                       ref="modal_button"
                       data-toggle="modal"
                       data-target="#exampleModal"></i>
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="long==true && longueur==0">
                <tr>
                  <td scope="row"
                      colspan="6"
                      class="text-center"> Aucune donnée disponible...</td>
                </tr>
              </tbody>
              <tbody v-else-if="long==false">
                <tr>
                  <td scope="row"
                      colspan="6"
                      class="text-center">Chargement en cours...</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="tab-pane fade"
             id="contact" 
             role="tabpanel" 
             aria-labelledby="contact-tab">
          <Situation v-if="choix=='situation'"/>
        </div>
        <div class="tab-pane fade"
             id="profile" 
             role="tabpanel" 
             aria-labelledby="profile-tab">
          <Bilan v-if="choix=='bilan'"
                 :comptes="planComptes"/>
        </div>
        <div class="tab-pane fade"
             id="address" 
             role="tabpanel" 
             aria-labelledby="address-tab">
          <CompteResultat v-if="choix=='resultat'"
                          :comptes="planComptes"/>
        </div>
      </div>
      
    </div>
    <!-- Modal -->
    <div class="modal fade"
         id="exampleModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"
                id="exampleModalLabel"
                v-if="av=='plan'">Ajouter un n° compte</h5>
            <h5 class="modal-title"
                id="exampleModalLabel"
                v-if="av=='modifplan'">Détail n° compte</h5>
            <button type="button"
                    class="close"
                    ref="btn-close"
                    data-dismiss="modal"
                    aria-label="Close"
                    @click="modPlan='',av=''">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body"
               v-if="av=='plan'">
            <AddPlan @plan="added => (code = added)"/>
          </div>
          <div class="modal-body"
               v-if="av=='modifplan'">
            <UpdatePlan :mod_plan="modPlan"
                        @upPlan="updated => (code = updated)"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
table.table.tablestyle_3.table-striped tbody td,
table.table.tablestyle_3.table-striped thead th{
  font-size: 0.7em;
}
form input[type="text"], 
form input[type="date"], 
form input[type="number"], 
form input[type="file"], 
form textarea,
form select option,
form select.form-control,
span[slot="no-options"].text-danger{
  font-size: 0.6em;
}
form select.form-control option{
  font-size: 1em;
}
input.vs__search{
  font-size: 0.7em;
}
form.form_default input[type="text"], 
form.form_default input[type="date"], 
form.form_default input[type="number"], 
form.form_default input[type="file"], 
form.form_default textarea{
  height: fit-content;
}
/* div.vs__dropdown-toggle,form select.form-control{
  height: 3.5vh;
} */

@media only screen and (width > 1466px){
  div.vs__dropdown-toggle,form select.form-control{
  height: 2.5vh;
  }
}
/* form.form-inline input,
form.form-inline select, */
li.nav-item
/* form.form-inline button */{
  font-size: 0.6em;
}
</style>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import form from "vuejs-form"
import Notif from "@/components/shared/Toast"
import AddPlan from "./Add.vue"
import UpdatePlan from "./Update.vue"
import Bilan from "./EtatFinanciers/Bilan.vue"
import CompteResultat from "./EtatFinanciers/CompteResultat.vue"
import Situation from "./EtatFinanciers/Situation.vue"

export default {
  name: "PlanComptes",
  components:{
    AddPlan,
    UpdatePlan,
    Bilan,
    CompteResultat,
    Situation,
    Notif
  },
  data: ()=>({
    av:"",
    modPlan:"",
    id_plan:"",
    code:false,
    user:"",
    base:"CONSOLIDE",
    comptes:[],
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    choix:"generale",
    form: form({
      id:""
    }).rules({
      id:"required"
    }),
    long:false,
    longueur:"",
  }),
  watch:{
    planComptes(){
      if (this.planComptes) {
        this.long=true
        this.longueur=this.planComptes.donnees.length
        this.comptes=[]
        if (this.base=="CONSOLIDE") {
          this.comptes=this.planComptes.donnees
        }else{
          this.planComptes.donnees.forEach(compte => {
            if (compte.base == this.base) {
              this.comptes.push(compte)
            }
          })
        }
        console.log(this.comptes)
      }
    },
    code(){
      if (this.code) {
        if (this.av=="plan") {
          this.notif.message = "Numero de compte créé avec succès."
        }else{
          if (this.av=="modifplan") {
            this.notif.message = "Numero de compte modifié avec succès."
          }
        }
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.long=false
            this.longueur=0
            this.setPlanComptes("")
            this.getListPlanComptes()
            this.$refs["btn-close"].click()
          }.bind(this),
          3000
        ) 
      }
    },
    msgSuccessPlanCompte(){
      if (this.msgSuccessPlanCompte) {
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        this.notif.message= "Numero de compte modifié avec succès"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.long=false
            this.longueur=0
            this.$refs["btn-close"].click()
            this.setMsgSuccessPlanCompte("")
            this.setPlanComptes("")
            this.getListPlanComptes()
          }.bind(this),
          3000
        ) 
      }
    },
    msgFailPlanCompte(){
      if (this.msgFailPlanCompte) {
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        this.notif.message= this.msgFailPlanCompte
        setTimeout(
          function() {
            this.notif.activated = ""
            this.long=false
            this.longueur=0
            this.$refs["btn-close"].click()
            this.setMsgFailPlanCompte("")
            this.setPlanComptes("")
            this.getListPlanComptes()
          }.bind(this),
          3000
        ) 
      }
    },
  },
  created(){
    this.getListPlanComptes()
    const userdetails = JSON.parse(localStorage.getItem("user"))
    this.user = userdetails.user_role.split("_")
  },
  computed:{
    ...mapGetters(["planComptes","msgSuccessPlanCompte","msgFailPlanCompte"])
  },
  methods:{
    ...mapActions(["getListPlanComptes"]),
    ...mapMutations(["setPlanComptes","setMsgSuccessPlanCompte","setMsgFailPlanCompte"]),
    updateForm(plan){
      this.modPlan=''
      this.modPlan=plan
    },
    reloadPlanCompte(){
      this.setPlanComptes("")
      this.long=false
      this.longueur=0
      this.getListPlanComptes()
    },
    changeBase(){
      this.comptes=[]
      if (this.base=="CONSOLIDE") {
        this.comptes=this.planComptes.donnees
      }else{
        this.planComptes.donnees.forEach(compte => {
          if (compte.base == this.base) {
            this.comptes.push(compte)
          }
        })
      }
      console.log(this.comptes)
    }
  }
}
</script>