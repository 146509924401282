<template>
  <form class="form">
    <div class="form-group">
      <label for="">Libellé</label>
      <input type="text"
             class="form-control" 
             name="" 
             id=""
             v-model="$parent.updaten2Form.libelle">
    </div>
  </form>
</template>
<style>
</style>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex"

export default({
  name:"UpdateN2",
  components:{},
  props:["niveau_2"],
  data:()=>({}),
  created(){
    this.getIdSituation_n2(this.niveau_2)
  },
  watch:{
    situation_id_n2(){
      if (this.situation_id_n2.situation_niveau_2) {
        this.$parent.updaten2Form.id = this.situation_id_n2.situation_niveau_2[0].id
        this.$parent.updaten2Form.libelle = this.situation_id_n2.situation_niveau_2[0].libelle
        this.$parent.updaten2Form.situation_niveau_1 = this.situation_id_n2.situation_niveau_2[0].libelle_niveau_1
      }
    }
  },
  computed:{
    ...mapGetters(["situation_id_n2"])
  },
  methods:{
    ...mapActions(["getIdSituation_n2"]),
    ...mapMutations(["setSituation_id_n2"])

  }
})
</script>