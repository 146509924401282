<template>
  <div>
    <div class="row">
      <Notif :notif="notif" />
    </div>
    <!-- <div class="row my-4">
      <button type="button"
              class="btn btn-blue"
              data-toggle="modal"
              data-target="#exampleModal"
              @click="selection('addn1')">Ajouter niveau 1</button>
    </div> -->
    <div class="row">
      <table class="table table-striped">
        <thead>
          <tr><th colspan="5"
                  class="th-blue text-center">AFFECTATION DES NUMEROS DE COMPTE</th></tr>
        </thead>
        <!-- BOUCLE AFFICHAGE NIVEAU 1 -->
        <tbody v-for="(niveau_1,nivkey1) in situations"
               :key="nivkey1">
          <tr>
            <td colspan="4"
                class="tht-dark-blue">{{ niveau_1.libelle }}</td>
            <td class="tht-dark-blue text-right"
                style="width:8%">
              <i class="flaticon-pencil mx-3"  
                 ref="modal_button"
                 data-toggle="modal"
                 data-target="#exampleModal"
                 @click="selection('updaten1'),n1=niveau_1.id"
                 v-if="niveau_1.libelle!='Emplois' 
                   && niveau_1.libelle!='Ressources'"></i>
              <i class="flaticon-delete mr-3"  
                 ref="modal_button"
                 data-toggle="modal"
                 data-target="#exampleModal"
                 @click="selection('deleten1'),n1=niveau_1.id"
                 v-if="niveau_1.libelle!='Emplois' 
                   && niveau_1.libelle!='Ressources'"></i>
              <i class="flaticon-add"  
                 ref="modal_button"
                 data-toggle="modal"
                 data-target="#exampleModal"
                 @click="selection('addn2'),n1=niveau_1.id"
                 v-if="niveau_1.libelle!='Emplois' 
                   && niveau_1.libelle!='Ressources'"></i>  
            </td>
          </tr>
          <!-- BOUCLE AFFICHAGE NIVEAU 2 -->
          <template v-for="(niveau_2,nivkey2) in niveau_1.niveau_n2">
            <tr :key="nivkey2">
              <td style="width:10%"
                  class="tht-blue"></td>
              <td colspan="3"
                  class="tht-blue">{{niveau_2.libelle}}</td>
              <td class="text-right tht-blue"
                  style="width:8%">
                <i class="flaticon-pencil mx-3"  
                   ref="modal_button"
                   data-toggle="modal"
                   data-target="#exampleModal"
                   @click="selection('updaten2'),n2=niveau_2.id"
                   v-if="niveau_2.libelle!='Revenus' 
                     && niveau_2.libelle!='Charges'"></i>
                <i class="flaticon-delete mr-3"  
                   ref="modal_button"
                   data-toggle="modal"
                   data-target="#exampleModal"
                   @click="selection('deleten2'),n2=niveau_2.id"
                   v-if="niveau_2.libelle!='Revenus' 
                     && niveau_2.libelle!='Charges'"></i>
                <i class="flaticon-add"  
                   ref="modal_button"
                   data-toggle="modal"
                   data-target="#exampleModal"
                   @click="selection('addn3'),n2=niveau_2.id"></i>  
              </td>
            </tr>
            <!-- BOUCLE AFFICHAGE NIVEAU 3 -->
            <template v-for="(niveau_3,nivkey3) in niveau_2.niveau_n3">
              <tr :key="nivkey2+'.'+nivkey3">
                <td style="width:10%"
                    class="th-blue"></td>
                <td style="width:10%"
                    class="th-blue"></td>
                <td colspan="2"
                    class="th-blue">{{niveau_3.libelle}}</td>
                <td class="text-right th-blue"
                    style="width:8%">
                  <i class="flaticon-pencil mx-3"  
                     ref="modal_button"
                     data-toggle="modal"
                     data-target="#exampleModal"
                     @click="selection('updaten3'),n3=niveau_3.id"
                     v-if="niveau_3.libelle!='Ressources affectées' 
                       && niveau_3.libelle!='DETTES FOURNISSEUR TRAVAUX' 
                       && niveau_3.libelle!='DETTES FOURNISSEUR FONCTIONNEMENT & INVESTISSEMENTS'"></i>
                  <i class="flaticon-delete mr-3"  
                     ref="modal_button"
                     data-toggle="modal"
                     data-target="#exampleModal"
                     @click="selection('deleten3'),n3=niveau_3.id"
                     v-if="niveau_3.libelle!='Ressources affectées' 
                       && niveau_3.libelle!='DETTES FOURNISSEUR TRAVAUX' 
                       && niveau_3.libelle!='DETTES FOURNISSEUR FONCTIONNEMENT & INVESTISSEMENTS'"></i>
                  <i class="flaticon-add"  
                     ref="modal_button"
                     data-toggle="modal"
                     data-target="#exampleModal"
                     @click="selection('addn4'),n3=niveau_3"></i>  
                </td>
              </tr>
              <!-- BOUCLE D'AFFICHAGE DES NIVEAUX 4 -->
              <template v-for="(niveau_4,nivkey4) in niveau_3.niveau_n4">
                <tr :key="niveau_2+'.'+nivkey3+'.'+nivkey4">
                  <td style="width:10%"></td>
                  <td style="width:10%"></td>
                  <td style="width:10%"></td>
                  <td>{{niveau_4.libelle}}</td>
                  <td class="text-right"
                      style="width:8%">
                    <i class="flaticon-pencil mx-3"  
                       ref="modal_button"
                       data-toggle="modal"
                       data-target="#exampleModal"
                       @click="selection('updaten4'),n4=niveau_4.id,n3=niveau_3"></i>
                    <i class="flaticon-delete"  
                       ref="modal_button"
                       data-toggle="modal"
                       data-target="#exampleModal"
                       @click="selection('deleten4'),n4=niveau_4.id"></i> 
                  </td>
                </tr>
              </template>
            </template>
          </template>
        </tbody>
      </table>
    </div>
    <!-- MODAL FORMS -->
    <div class="modal fade" 
         id="exampleModal" 
         tabindex="-1" 
         role="dialog" 
         aria-labelledby="exampleModalLabel" 
         aria-hidden="true">
      <div class="modal-dialog" 
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" 
                id="exampleModalLabel">{{ titre }}</h5>
            
            <button type="button" 
                    class="close"
                    ref="btn-close"
                    data-dismiss="modal" 
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!-- Formulaire d'ajout -->
            <AddN1 v-if="niveau=='addn1'"/>
            <AddN2 v-if="niveau=='addn2'"
                   :niveau_1="n1"/>
            <AddN3 v-if="niveau=='addn3'"
                   :niveau_2="n2"/>
            <AddN4 v-if="niveau=='addn4'"
                   :niveau_3="n3"/>
            <!-- Formilaire de modification -->
            <UpdateN1 v-if="niveau=='updaten1'"
                      :niveau_1="n1"/>
            <UpdateN2 v-if="niveau=='updaten2'"
                      :niveau_2="n2"/>
            <UpdateN3 v-if="niveau=='updaten3'"
                      :niveau_3="n3"/>
            <UpdateN4 v-if="niveau=='updaten4'"
                      :niveau_4="n4"
                      :niveau_3="n3"/>
            <p class="text-center"
               v-if="niveau.split('')[0]=='d'">
              Êtes-vous sûr(e) de vouloir supprimer cet élément? <br>
              PS: cette action sera irréversible.
            </p>
          </div>
          <div class="modal-footer"
               v-if="niveau.split('')[0]!='d'">
            <button type="button" 
                    class="btn btn-f-blue" 
                    data-dismiss="modal"
                    @click="annuler()">Annuler</button>
            <button type="button" 
                    class="btn btn-f-blue"
                    @click="soummettreForm()">Enregistrer</button>
          </div>
          <div class="modal-footer"
               v-else>
            <button type="button" 
                    class="btn btn-f-blue" 
                    data-dismiss="modal"
                    @click="annuler()">Non</button>
            <button type="button" 
                    class="btn btn-f-blue"
                    @click="soummettreForm()">Oui</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
table.table-striped tbody{
  font-size: 0.7em;
}
</style>
<script>
// import VSelect from "vue-select"
// import "vue-select/dist/vue-select.css"
import Notif from "@/components/shared/Toast"

import { mapActions, mapGetters, mapMutations } from "vuex"
import AddN1 from "./SituationForms/AddN1"
import AddN2 from "./SituationForms/AddN2"
import AddN3 from "./SituationForms/AddN3"
import AddN4 from "./SituationForms/AddN4"
import UpdateN1 from "./SituationForms/UpdateN1"
import UpdateN2 from "./SituationForms/UpdateN2"
import UpdateN3 from "./SituationForms/UpdateN3"
import UpdateN4 from "./SituationForms/UpdateN4"
export default{
  name:"Situation",
  components:{
    // VSelect,
    Notif,
    AddN1,
    AddN2,
    AddN3,
    AddN4,
    UpdateN1,
    UpdateN2,
    UpdateN3,
    UpdateN4,
  },
  props:[],
  data:()=>({
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    addn1Form:{
      libelle:""
    },
    updaten1Form:{
      id:"",
      libelle:""
    },
    addn2Form:{
      libelle:"",
      situation_niveau_1:""
    },
    updaten2Form:{
      id:"",
      libelle:"",
      situation_niveau_1:""
    },
    addn3Form:{
      libelle:"",
      situation_niveau_2:"",
      base:""
    },
    updaten3Form:{
      id:"",
      libelle:"",
      situation_niveau_2:"",
      base:""
    },
    addn4Form:{
      libelle:"",
      situation_niveau_3:"",
      type_solde:"",
      numero_compte:[]
    },
    updaten4Form:{
      id:"",
      libelle:"",
      situation_niveau_3:"",
      type_solde:"",
      numero_compte:[]
    },
    situations:[],
    niveau:"",
    titre:"",
    n1:"",
    n2:"",
    n3:"",
    n4:"",
  }),
  watch:{
    situations_n1(){
      if (this.situations_n1.donnees) {
        this.situations_n1.donnees.forEach(element => {
          this.situations.push({id:element.id,libelle:element.libelle,niveau_n2:[]})
        })
        this.getSituation_n2()
      }
    },
    situations_n2(){
      if (this.situations_n2.donnees) {
        this.situations.forEach(situation => {
          this.situations_n2.donnees.forEach(element => {
            if (situation.libelle == element.situation_niveau_1) {
              situation.niveau_n2.push({id:element.id,libelle:element.libelle,niveau_n3:[]})
            }
          })
        })
        // console.log(this.situations)
        this.getSituation_n3()
      }
    },
    situations_n3(){
      if (this.situations_n3.donnees) {
        this.situations.forEach(niveau1 => {//parcours les niveaux 1
          niveau1.niveau_n2.forEach(situation => {//parcours les niveaux 2 de chaque niveau 1
            this.situations_n3.donnees.forEach(element => {// parcours tous les niveaux 3
              if (situation.libelle == element.situation_niveau_2) {// vérifie la correspondance entre les niveaux 3 et les niveaux 2 
                situation.niveau_n3.push({id:element.id,libelle:element.libelle,base:element.base,niveau_n4:[]})// si le niveau 3 correspond au niveau 2 il est enregistré
              }
            })
          })
        })
        console.log(this.situations)
        this.getSituation_n4()
      }
    },
    situations_n4(){
      if (this.situations_n4.donnees) {
        this.situations.forEach(niveau1 => {//parcours les niveaux 1
          niveau1.niveau_n2.forEach(niveau2 => {//parcours les niveaux 2 de chaque niveau 1
            niveau2.niveau_n3.forEach(situation => {//parcours les niveaux 3 de chaque niveau 2
              this.situations_n4.donnees.forEach(element => {// parcours tous les niveaux 4
                if (situation.id == element.situation_niveau_3) {// vérifie la correspondance entre les niveaux 4 et les niveaux 3 
                  situation.niveau_n4.push({
                    id:element.id,
                    libelle:element.libelle,
                    type_solde:element.type_solde,
                    numero_compte:element.numero_compte
                  })// si le niveau 4 correspond au niveau 3 il est enregistré
                }
              })
            })
          })
        })
        console.log(this.situations)
      }
    },
    situation_n1(){
      if (this.situation_n1) {
        this.notif.message = this.situation_n1
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = "desactivated"
            this.setSituation_n1("")
            // this.loaded=false
            this.niveau=""
            this.getSituation_n1()
            this.setSituation_n1("")
            this.situations=[]
            this.$refs["btn-close"].click()
            this.addn1Form={
              libelle:""
            }
            this.updaten1Form={
              id:"",
              libelle:""
            }
          }.bind(this),
          3000
        )
      }
    },
    situation_n2(){
      if (this.situation_n2) {
        this.notif.message = this.situation_n2
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = "desactivated"
            this.setSituation_n2("")
            // this.loaded=false
            this.niveau=""
            this.getSituation_n1()
            this.setSituation_n2("")
            this.situations=[]
            this.$refs["btn-close"].click()
            this.addn2Form={
              libelle:"",
              situation_niveau_1:""
            }
            this.updaten2Form={
              id:"",
              libelle:"",
              situation_niveau_1:""
            }
          }.bind(this),
          3000
        )
      }
    },
    situation_n3(){
      if (this.situation_n3) {
        this.notif.message = this.situation_n3
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = "desactivated"
            this.setSituation_n3("")
            // this.loaded=false
            this.niveau=""
            this.getSituation_n1()
            this.setSituation_n3("")
            this.situations=[]
            this.$refs["btn-close"].click()
            this.addn3Form={
              libelle:"",
              situation_niveau_2:"",
              base:""
            }
            this.updaten3Form={
              id:"",
              libelle:"",
              situation_niveau_2:"",
              base:""
            }
          }.bind(this),
          3000
        )
      }
    },
    situation_n4(){
      if (this.situation_n4) {
        this.notif.message = this.situation_n4
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = "desactivated"
            this.setSituation_n4("")
            // this.loaded=false
            this.niveau=""
            this.getSituation_n1()
            this.setSituation_n4("")
            this.situations=[]
            this.$refs["btn-close"].click()
            this.addn4Form={
              libelle:"",
              situation_niveau_3:"",
              type_solde:"",
              numero_compte:[]
            }
            this.updaten4Form={
              id:"",
              libelle:"",
              situation_niveau_3:"",
              type_solde:"",
              numero_compte:[]
            }
          }.bind(this),
          3000
        )
      }
    },
    niveau(){
      if(this.niveau){
        var separateur=this.niveau.split("")
        if(separateur[0] =="a"){
          this.titre="Ajouter niveau "
        }else{
          if (separateur[0]=="u") {
            this.titre="Modifier niveau "
          } else {
            this.titre="Supprimer niveau "
          }
        }
        this.titre+=separateur[separateur.length-1]
      }
    }
  },
  created(){
    this.getSituation_n1()
  },
  computed:{
    ...mapGetters(["situation_n1","situations_n1","msgSituations_n1","situation_n2","situations_n2","msgSituations_n2","situation_n3","situations_n3","msgSituations_n3","situation_n4","situations_n4","msgSituations_n4"])
  },
  methods:{
    ...mapActions(["postSituation_n1","getSituation_n1","putSituation_n1","deleteSituation_n1","postSituation_n2","getSituation_n2","putSituation_n2","deleteSituation_n2","postSituation_n3","getSituation_n3","putSituation_n3","deleteSituation_n3","postSituation_n4","getSituation_n4","putSituation_n4","deleteSituation_n4",]),
    ...mapMutations(["setSituation_n1","setSituations_n1","setMsgSituations_n1","setSituation_n2","setSituations_n2","setMsgSituations_n2","setSituation_n3","setSituations_n3","setMsgSituations_n3","setSituation_n4","setSituations_n4","setMsgSituations_n4"]),
    selection(n){
      this.niveau=""
      if(n == "addn1"){
        this.addn1Form={
          libelle:""
        }
      }else{
        if(n == "updaten1"){
          this.updaten1Form={
            id:"",
            libelle:""
          }
        }else{
          if(n == "addn2"){
            this.addn2Form={
              libelle:"",
              situation_niveau_1:""
            }
          }else{
            if(n == "updaten2"){
              this.updaten2Form={
                id:"",
                libelle:"",
                situation_niveau_1:""
              }
            }else{
              if(n == "addn3"){
                this.addn3Form = {
                  libelle:"",
                  situation_niveau_2:"",
                  base:""
                }
              }else{
                if(n == "updaten3"){
                  this.updaten3Form = {
                    id:"",
                    libelle:"",
                    situation_niveau_2:"",
                    base:""
                  }
                }else{
                  if(n == "addn4"){
                    console.log(this.n3)
                    this.addn4Form={
                      libelle:"",
                      situation_niveau_3:"",
                      type_solde:"",
                      numero_compte:[]
                    }
                  }else{
                    if(n == "updaten4"){
                      this.updaten4Form={
                        id:"",
                        libelle:"",
                        situation_niveau_3:"",
                        type_solde:"",
                        numero_compte:[]
                      }
                    }
                  }
                }  
              }
            }  
          }
        }  
      }
      setTimeout(
        function() {
          this.niveau=n
        }.bind(this),
        300
      )
    },
    soummettreForm(){
      // console.log(this[this.niveau+"Form"])
      if(this.niveau == "addn1"){
        this.postSituation_n1(this.addn1Form)
      }else{
        if(this.niveau == "updaten1"){
          this.putSituation_n1(this.updaten1Form)
        }else{
          if(this.niveau == "addn2"){
            this.postSituation_n2(this.addn2Form)
          }else{
            if(this.niveau == "updaten2"){
              this.putSituation_n2(this.updaten2Form)
            }else{
              if(this.niveau == "addn3"){
                this.postSituation_n3(this.addn3Form)
              }else{
                if(this.niveau == "updaten3"){
                  this.putSituation_n3(this.updaten3Form)
                }else{
                  if(this.niveau == "addn4"){
                    console.log(this.n3)
                    this.postSituation_n4(this.addn4Form)
                  }else{
                    if(this.niveau == "updaten4"){
                      this.putSituation_n4(this.updaten4Form)
                    }else{
                      if(this.niveau == "deleten1"){
                        this.deleteSituation_n1(this.n1)
                      }else{
                        if(this.niveau == "deleten2"){
                          this.deleteSituation_n2(this.n2)
                        }else{
                          if(this.niveau == "deleten3"){
                            this.deleteSituation_n3(this.n3)
                          }else{
                            if(this.niveau == "deleten4"){
                              this.deleteSituation_n4(this.n4)
                            }
                          }
                        }
                      }
                    }
                  }
                }  
              }
            }  
          }
        }  
      }
    }
  }
}
</script>