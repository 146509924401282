<template>
  <form class="form">
    <div class="form-group">
      <label for="">Libellé</label>
      <input type="text"
             class="form-control" 
             name="" 
             id=""
             v-model="$parent.updaten3Form.libelle">
    </div>
    <div class="form-group">
      <label for="">Base</label>
      <select class="form-control" 
              name="" 
              id=""
              v-model="$parent.updaten3Form.base">
        <option value="1">SIEGE</option>
        <option value="2">PEAGE</option>
        <option value="3">PESAGE</option>
        <option value="7">AIRE DE STATIONNEMENT</option>
      </select>
    </div>
  </form>
</template>
<style>
</style>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex"

export default({
  name:"UpdateN3",
  components:{},
  props:["niveau_3"],
  data:()=>({}),
  created(){
    this.getIdSituation_n3(this.niveau_3)
  },
  watch:{
    situation_id_n3(){
      if (this.situation_id_n3.situation_niveau_3) {
        this.$parent.updaten3Form.id = this.situation_id_n3.situation_niveau_3[0].id
        this.$parent.updaten3Form.libelle = this.situation_id_n3.situation_niveau_3[0].libelle
        this.$parent.updaten3Form.base= this.situation_id_n3.situation_niveau_3[0].base
        this.$parent.updaten3Form.situation_niveau_2 = this.situation_id_n3.situation_niveau_3[0].id_niveau_2
      }
    }
  },
  computed:{
    ...mapGetters(["situation_id_n3"])
  },
  methods:{
    ...mapActions(["getIdSituation_n3"]),
    ...mapMutations(["setSituation_id_n3"])

  }
})
</script>