<template>
  <form class="form">
    <div class="form-group">
      <label for="">Libellé</label>
      <input type="text"
             class="form-control" 
             name="" 
             id=""
             v-model="$parent.addn2Form.libelle">
    </div>
  </form>
</template>
<style>
</style>
<script>
export default({
  name:"AddN2",
  components:{},
  props:["niveau_1"],
  data:()=>({}),
  watch:{

  },
  created(){
    this.$parent.addn2Form.situation_niveau_1 = this.niveau_1
  },
  computed:{},
  methods:{}
})
</script>