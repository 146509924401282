<template>
  <form class="form">
    <div class="form-group">
      <label for="">Libellé</label>
      <input type="text"
             class="form-control" 
             name="" 
             id=""
             v-model="$parent.addn4Form.libelle">
    </div>
    <div class="form-group">
      <label for="">Type de Solde</label>
      <select class="form-control" 
              name="" 
              id=""
              v-model="$parent.addn4Form.type_solde">
        <option value="CR">Total crédit</option>
        <option value="DR">Total débit</option>
        <option value="MC">Mouvements crédit</option>
        <option value="MD">Mouvements débit</option>
        <option value="SOLDE">Solde</option>
      </select>
    </div>
    <div class="form-group">
      <label for="">N° de compte associés</label>
      <v-select :options="listComptes.comptes"
                :reduce="compte => compte.compte"
                label="description"
                ref="comptes"
                id="compte"
                multiple
                placeholder="Choisir un numéro de compte comptable"
                v-model="$parent.addn4Form.numero_compte">
      </v-select>
    </div>
  </form>
</template>
<style>
</style>
<script>
import { mapActions, mapGetters } from "vuex"
import VSelect from "vue-select"
export default({
  name:"AddN4",
  components:{
    VSelect
  },
  props:["niveau_3"],
  data:()=>({
    listComptes:{comptes:[]},
    base:"",
  }),
  watch:{
    planComptes(){
      if (this.planComptes.donnees) {
        
        switch (this.niveau_3.base) {
          case 1:
            this.base= "SIEGE"
            break
          case 2:
            this.base= "PEAGE"
            break
          case 3:
            this.base= "PESAGE"
            break
          default:
            this.base= "AIRE_STATIONNEMENT"
            break
        }
        console.log(this.base)

        this.planComptes.donnees.forEach(compte => {
          if (this.base==compte.base) {
            this.listComptes.comptes.push({id:compte.id,description:compte.compte+'-'+compte.description,compte:compte.compte}) 
          }
        })  
        console.log(this.listComptes)
        
      }
      
    },
  },
  created(){
    this.$parent.addn4Form.situation_niveau_3 = this.niveau_3.id
    console.log(this.niveau_3)
    this.getListPlanComptes()
  },
  computed:{
    ...mapGetters(["planComptes"])
  },
  methods:{
    ...mapActions(["getListPlanComptes"]),
  }
})
</script>