<template>
  <form class="form">
    <div class="form-group">
      <label for="">Libellé</label>
      <input type="text"
             class="form-control" 
             name="" 
             id=""
             v-model="$parent.updaten1Form.libelle">
    </div>
  </form>
</template>
<style>
</style>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex"

export default({
  name:"UpdateN1",
  components:{},
  props:["niveau_1"],
  data:()=>({}),
  created(){
    this.getIdSituation_n1(this.niveau_1)
  },
  watch:{
    situation_id_n1(){
      if (this.situation_id_n1.situation_niveau_1) {
        this.$parent.updaten1Form.id = this.situation_id_n1.situation_niveau_1[0].id
        this.$parent.updaten1Form.libelle = this.situation_id_n1.situation_niveau_1[0].libelle
      }
    }
  },
  computed:{
    ...mapGetters(["situation_id_n1"])
  },
  methods:{
    ...mapActions(["getIdSituation_n1"]),
    ...mapMutations(["setSituation_id_n1"])

  }
})
</script>