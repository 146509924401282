<template>
  <form class="form">
    <div class="form-group">
      <label for="">Libellé</label>
      <input type="text"
             class="form-control" 
             name="" 
             id=""
             v-model="$parent.addn3Form.libelle">
    </div>
    <div class="form-group">
      <label for="">Base</label>
      <select class="form-control" 
              name="" 
              id=""
              v-model="$parent.addn3Form.base">
        <option value="1">SIEGE</option>
        <option value="2">PEAGE</option>
        <option value="3">PESAGE</option>
        <option value="7">AIRE DE STATIONNEMENT</option>
      </select>
    </div>
  </form>
</template>
<style>
</style>
<script>
export default({
  name:"AddN3",
  components:{},
  props:["niveau_2"],
  data:()=>({}),
  watch:{

  },
  created(){
    this.$parent.addn3Form.situation_niveau_2 = this.niveau_2
  },
  computed:{},
  methods:{}
})
</script>