<template>
  <form class="form">
    <div class="form-group">
      <label for="">Libellé</label>
      <input type="text"
             class="form-control" 
             name="" 
             id=""
             v-model="$parent.addn1Form.libelle">
    </div>
  </form>
</template>
<style>
</style>
<script>
export default({
  name:"AddN1",
  components:{},
  props:[],
  data:()=>({}),
  watch:{},
  computed:{},
  methods:{}
})
</script>