<template>
  <div>
    <div class="row">
      <Notif :notif="notif" />
    </div>
    <div class="row my-3">
      <button class="btn btn-blue"
              ref="modal_button"
              data-toggle="modal"
              data-target="#exampleModal"
              @click="bil='ajouter'">
        Ajouter un groupe
      </button>
    </div>
    <table class="table"
           style="font-size: 0.6em;">
      <thead>
        <tr>
          <th class="th-blue">Libellé</th>
          <th class="th-blue text-right">Action</th>
        </tr>
      </thead>
      <tbody v-if="!loaded">
        <tr>
          <td colspan="2"
              class="text-center">Chargement en cours...</td>
        </tr>
      </tbody>
      <tbody v-else-if="loaded && groupes.donnees.length==0">
        <tr>
          <td colspan="2"
              class="text-center">Aucune donnée enregistrée.</td>
        </tr>
      </tbody>
      <tbody v-else>
        <template v-for="(groupe,gkey) in groupes.donnees">
          <tr :key="'groupe'+gkey"
              v-if="groupe.etat_financier=='COMPTE_RESULTAT'">
            <td scope="row"
                class="clickable"
                @click="showSousGroupe(groupe.id)"><strong>{{ groupe.libelle }}</strong></td>
            <td scope="row"
                class="text-right">
              <i class="flaticon-pencil mx-3"  
                 ref="modal_button"
                 data-toggle="modal"
                 data-target="#exampleModal"
                 @click="bil='modifier',updateFormGroupe(groupe)"></i>
              <i class="flaticon-add"  
                 ref="modal_button"
                 data-toggle="modal"
                 data-target="#exampleModal"
                 @click="bil='ajouter-sc',id_groupe=groupe.id"></i>
            </td>
          </tr>
          <template v-for="(sousGroupe,soukey) in sousGroupes.donnees">
            <tr v-if="groupe.id==id_groupe"
                :key="'sousgroupe'+soukey+'groupe'+gkey">
              <td class="clickable">{{ sousGroupe.libelle }}</td>
              <td class="text-right">
                <i class="flaticon-pencil"  
                   ref="modal_button"
                   data-toggle="modal"
                   data-target="#exampleModal"
                   @click="bil='modifier-sc',updateFormSousGroupe(groupe.id,sousGroupe)"></i>
              </td>
            </tr>
          </template>
        </template>
        
      </tbody>
    </table>
    <!-- Modal -->
    <div class="modal fade"
         id="exampleModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"
                id="exampleModalLabel"
                v-if="bil=='ajouter'">Ajouter un groupe</h5>
            <h5 class="modal-title"
                id="exampleModalLabel"
                v-if="bil=='modifier'">Modifier un groupe</h5>
            <h5 class="modal-title"
                id="exampleModalLabel"
                v-if="bil=='ajouter-sc'">Ajouter un sous-groupe</h5>
            <h5 class="modal-title"
                id="exampleModalLabel"
                v-if="bil=='modifier-sc'">Modifier un sous-groupe</h5>
            <button type="button"
                    class="close"
                    ref="btn-close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body"
               v-if="bil=='ajouter'">
            <form class="form">
              <div class="form-group">
                <label for="">Libellé</label>
                <input type="text"
                       class="form-control"
                       v-model="groupeForm.libelle">
              </div>              
              <div class="form-group">
                <label for="">Type du groupre</label>
                <select class="form-control" 
                        name="" 
                        id=""
                        v-model="groupeForm.statut_groupe">
                  <option value="ACTIF">Actif</option>
                  <option value="PASSIF">Passif</option>
                  
                </select>
              </div>
              <div class="form-group">
                <button class="btn btn-f-blue">Annuler</button>
                <button class="btn btn-f-blue"
                        type="button"
                        @click="createGroupe()">Enregistrer</button></div>
            </form>
          </div>
          <div class="modal-body"
               v-if="bil=='modifier'">
            <form class="form">
              <div class="form-group">
                <label for="">Libellé</label>
                <input type="text"
                       class="form-control"
                       v-model="groupeForm.libelle">
              </div>
              
              <div class="form-group">
                <label for="">Type du groupre</label>
                <select class="form-control" 
                        name="" 
                        id=""
                        v-model="groupeForm.statut_groupe">
                  <option value="ACTIF">Actif</option>
                  <option value="PASSIF">Passif</option>
                  
                </select>
              </div>
              <div class="form-group">
                <button class="btn btn-f-blue">Annuler</button>
                <button class="btn btn-f-blue"
                        type="button"
                        @click="updateGroupe()">Enregistrer</button></div>
            </form>
          </div>
          <div class="modal-body"
               v-if="bil=='ajouter-sc'">
            <form class="form">
              <div class="form-group">
                <label for="">Libellé</label>
                <input type="text"
                       class="form-control"
                       v-model="sousGroupeForm.libelle">
              </div>
              <div class="form-group">
                <label for="">Référence</label>
                <input type="text"
                       class="form-control"
                       v-model="sousGroupeForm.reference">
              </div>
              <div class="form-group">
                <label for="">N° comptes associés</label>
                <v-select :options="listComptes.comptes"
                          :reduce="compte => compte.id"
                          label="description"
                          ref="comptes"
                          id="compte"
                          multiple
                          placeholder="Choisir un numéro de compte comptable"
                          v-model="sousGroupeForm.numero_compte">
                  
                </v-select>
              </div>
              <div class="form-group">
                <label for="">Catégorie du N° comptes</label>
                
                <select class="form-control" 
                        name="" 
                        id=""
                        v-model="sousGroupeForm.statut_sous_groupe">
                  <option value="BRUTE">Brute</option>
                  <option value="AMORTISSEMENT">Amortissement/Dépréciation</option>
                </select>
                
              </div>
              
              <div class="form-group">
                <label for="">Type du N° comptes associés</label>
                <select class="form-control" 
                        name="" 
                        id=""
                        v-model="sousGroupeForm.type_solde">
                  <option value="SOLDE">Solde</option>
                  <option value="DR">total débit</option>
                  <option value="CR">total crédit</option>
                </select>
              </div>
              <div class="form-group">
                <button class="btn btn-f-blue" 
                        type="button">Annuler</button>
                <button class="btn btn-f-blue" 
                        type="button"
                        @click="createSousGroupe()">Enregistrer</button></div>
            </form>
          </div>
          <div class="modal-body"
               v-if="bil=='modifier-sc'">
            <form class="form">
              <div class="form-group">
                <label for="">Libellé</label>
                <input type="text"
                       class="form-control"
                       v-model="sousGroupeForm.libelle">
              </div>
              <div class="form-group">
                <label for="">N° comptes associés</label>
                <v-select :options="listComptes.comptes"
                          :reduce="compte => compte.id"
                          label="description"
                          ref="comptes"
                          id="compte"
                          multiple
                          placeholder="Choisir un numéro de compte comptable"
                          v-model="sousGroupeForm.numero_compte">
                  
                </v-select>
              </div>
              <div class="form-group">
                <label for="">Catégorie du N° comptes</label>
                
                <select class="form-control" 
                        name="" 
                        id=""
                        v-model="sousGroupeForm.statut_sous_groupe">
                  <option value="BRUTE">Brute</option>
                  <option value="AMORTISSEMENT">Amortissement/Dépréciation</option>
                </select>
                
              </div>
              
              <div class="form-group">
                <label for="">Type du N° comptes associés</label>
                <select class="form-control" 
                        name="" 
                        id=""
                        v-model="sousGroupeForm.type_solde">
                  <option value="SOLDE">Solde</option>
                  <option value="DR">total débit</option>
                  <option value="CR">total crédit</option>
                </select>
              </div>
              <div class="form-group">
                <button class="btn btn-f-blue" 
                        type="button">Annuler</button>
                <button class="btn btn-f-blue" 
                        type="button"
                        @click="updateSousGroupe()">Enregistrer</button></div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>  
</template>
<style>
table.table .clickable{
  cursor: pointer;
}
</style>
<script>
import VSelect from "vue-select"
import "vue-select/dist/vue-select.css"
import Notif from "@/components/shared/Toast"

import { mapActions, mapGetters, mapMutations } from "vuex"

export default{
  name:"CompteResultat",
  components:{
    VSelect,
    Notif
  },
  props:["comptes"],
  data: ()=>({
    bil:'ajouter',
    listComptes:{comptes:[]},
    groupeForm:{
      id:"",
      libelle:"",
      etat_financier:"COMPTE_RESULTAT",
      statut_groupe:"",
    },
    sousGroupeForm:{
      id:"",
      libelle:"",
      etat_financier_groupes:"",
      statut_sous_groupe:"",
      numero_compte:"",
      type_solde:"",
    },
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    id_groupe:"",
    loaded:false
  }),
  watch:{
    groupes(){
      if (this.groupes.donnees) {
        this.loaded=true
      }
    },
    sousGroupe(){
      if (this.sousGroupe.etat_financier_sous_groupe) {
        
        this.sousGroupeForm.id = this.sousGroupe.etat_financier_sous_groupe[0].id
        this.sousGroupeForm.libelle = this.sousGroupe.etat_financier_sous_groupe[0].libelle
        this.sousGroupeForm.etat_financier_groupes = this.sousGroupe.etat_financier_sous_groupe[0].etat_financier_groupes
        this.sousGroupeForm.numero_compte = []
        
        this.sousGroupe.etat_financier_sous_groupe[0].numero_compte.forEach(element => {
          this.listComptes.comptes.forEach(compte =>{
            if (element.compte == compte.id) {
              this.sousGroupeForm.numero_compte.push(compte.description)  
            }
          })
        })
        
        this.sousGroupeForm.statut_sous_groupe = this.sousGroupe.etat_financier_sous_groupe[0].statut_sous_groupe
        this.sousGroupeForm.type_solde = this.sousGroupe.etat_financier_sous_groupe[0].type_solde
      }
    },
    msgSuccessGroupe(){
      if (this.msgSuccessGroupe) {
        this.notif.message = "Action groupe effectuée"
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = "desactivated"
            this.setGroupes("")
            this.loaded=false
            this.getListGroupes()
            this.setMsgSuccessGroupe("")
            this.$refs["btn-close"].click()
            this.groupeForm={
              id:"",
              libelle:"",
              etat_financier:"",
              statut_groupe:"",
            }
          }.bind(this),
          5000
        )
        
      }
    },
    msgSuccessSousGroupe(){
      if (this.msgSuccessSousGroupe) {
        this.notif.message = "Action sous-groupe effectuée"
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = "desactivated"
            this.setGroupes("")
            this.setSousGroupes("")
            this.loaded=false
            this.getListGroupes()
            this.getListSousGroupes()
            this.setMsgSuccessSousGroupe("")
            this.$refs["btn-close"].click()
            this.sousGroupeForm={
              id:"",
              libelle:"",
              etat_financier_groupes:"",
              statut_sous_groupe:"",
              numero_compte:"",
              type_solde:"",
            }
          }.bind(this),
          5000
        )
        
      }
    }
  },
  created(){
    this.getListGroupes()
    this.comptes.donnees.forEach(compte => {
      this.listComptes.comptes.push({id:compte.id,description:compte.compte+'-'+compte.description})
    })
    console.log(this.listComptes)
  },
  computed:{
    ...mapGetters(["groupes","groupe","sousGroupes","sousGroupe","msgSuccessGroupe","msgSuccessSousGroupe"])
  },
  methods:{
    ...mapActions(["getListGroupes","getListSousGroupes","getSousGroupe","postGroupe","putGroupe","postSousGroupe","putSousGroupe"]),
    ...mapMutations(["setGroupes","setGroupe","setSousGroupes","setSousGroupe","setMsgSuccessGroupe","setMsgSuccessSousGroupe"]),
    createGroupe(){
      this.postGroupe(this.groupeForm)
    },
    createSousGroupe(){
      this.sousGroupeForm.etat_financier_groupes=this.id_groupe
      this.postSousGroupe(this.sousGroupeForm)
      // console.log(this.sousGroupeForm)
    },
    showSousGroupe(id){
      this.id_groupe=""
      this.id_groupe=id
      this.setSousGroupes("")
      this.getListSousGroupes(id)
    },
    updateFormGroupe(groupe){
      this.groupeForm.id = groupe.id
      this.groupeForm.libelle = groupe.libelle
      this.groupeForm.etat_financier = groupe.etat_financier
      this.groupeForm.statut_groupe = groupe.statut_groupe
    },
    updateFormSousGroupe(id_groupe,sousGroupe){
      this.getSousGroupe({etat_financier_groupes:id_groupe,id:sousGroupe.id})
    },
    updateGroupe(){
      this.putGroupe(this.groupeForm)
    },
    updateSousGroupe(){
      this.putSousGroupe(this.sousGroupeForm)
    }
  }
}
</script>