<template>
  <div>
    <div class="row">
      <Notif :notif="notif" />
    </div>
    <form class="form_default">
      <div class="form-group">
        <label>Numéro de compte</label>
        <input
          type="text"
          name="libelle"
          v-model='form.compte'
          class="form-control form-control-lg"
          aria-label=".form-control-lg example"
          autocomplete="off">
        <span
          v-if="form.errors().has('libelle')"
          v-text="form.errors().get('libelle')"
          class="errorMsg">
        </span>
      </div>
      <div class="form-group">
        <label>Intitulé</label>
        <input type="text"
               class="form-control"
               v-model="form.description">
        <span
          v-if="form.errors().has('desciption')"
          v-text="form.errors().get('desciption')"
          class="errorMsg">
        </span>
      </div>
      <div class="form-group">
        <label>Base</label>
        <select class="form-control" 
                name="" 
                id=""
                v-model="form.base">
          <option value="">--Choisissez une base--</option>
          <option :value="'SIEGE'">SIEGE</option>
          <option :value="'PEAGE'">PEAGE</option>
          <option :value="'PESAGE'">PESAGE</option>
          <option :value="'AIRE_STATIONNEMENT'">AIRE DE STATIONNEMENT</option>
        </select>
        <span
          v-if="form.errors().has('base')"
          v-text="form.errors().get('base')"
          class="errorMsg">
        </span>
      </div>
      <div class="form-group">
        <label>Type</label>
        <select class="form-control" 
                name="" 
                id=""
                v-model="form.flux">
          <option value="">--Choisissez un type--</option>
          <option :value="'REVENUS'">RESSOURCES</option>
          <option :value="'CHARGES'">EMPLOIS</option>
        </select>
        <span
          v-if="form.errors().has('base')"
          v-text="form.errors().get('base')"
          class="errorMsg">
        </span>
      </div>
      <div class="form-group">
        <label for="">Catégorie de compte:</label>
        <select class="form-control"
                v-model="form.categorie">
          <option value="">--Choisissez une catégorie--</option>
          <option value="COMPTES_TRAVAUX_ROUTIERS">Travaux routiers</option>
          <option value="COMPTES_FONCTIONNEMENT_INVESTISSEMENTS">Fonctionnement & Investissements</option>
          <option value="COMPTES_PROVISION">Domiciliations & provisions</option>
          <option value="COMPTES_RESERVE">Sequestres</option>
          <option value="AUTRES">Caisses</option>
        </select>
      </div>
      <!-- <div class="form-group">
        <label>Etat</label>
        <select class="form-control" 
                name="" 
                id=""
                v-model="form.etat">
          <option value="">--Choisissez un etat--</option>
          <option :value="'ACTIF'">Actif</option>
          <option :value="'INACTIF'">Inactif</option>
        </select>
        <span
          v-if="form.errors().has('etat')"
          v-text="form.errors().get('etat')"
          class="errorMsg">
        </span>
      </div> -->
      <div class="form-group">
        <label>Date de l'inactivité</label>
        <input type="date"
               class="form-control"
               v-model="form.date_inactif">
      </div>
      <!-- <div class="form-group">
        <label>Etat financier</label>
        <select class="form-control" 
                name="" 
                id=""
                v-model="form.etat">
          <option value="">--Choisissez un etat--</option>
          <option :value="'BILAN'">Bilan</option>
          <option :value="'RESULTAT'">Compte résultat</option>
        </select>
        <span
          v-if="form.errors().has('etat')"
          v-text="form.errors().get('etat')"
          class="errorMsg">
        </span>
      </div>
      <div class="form-group">
        <label>Rubrique état financier</label>
        <select class="form-control" 
                name="" 
                id=""
                v-model="form.rubrique_etat">
          <option value="">--Choisissez une rubrique--</option>
          
          < !-- COMPTE RESULTAT -- >
          <optgroup v-if="form.etat=='RESULTAT'" 
                    label="Produits d'exploitation">
            <option value="revenus">Revenus</option>
            <option value="autres_produits_exploitation">Autres produits d'exploitation</option>
            <option value="production_immobilisee">Production immobilisée</option>
          </optgroup>
          <optgroup v-if="form.etat=='RESULTAT'" 
                    label="Charges d'exploitation">
            <option value="variation_des_stocks_des_produits_finis_et_des_encours">Variation des stocks des produits finis et des encours (en+ou-)</option>
            <option value="Achats_marchandises_consommes">Achats de marchandises consommés</option>
            <option value="charges_personnel">Charges de personnel</option>
            <option value="dotations_amortissements_et_provisions">Dotations aux amortissements et aux provisions (non financières)</option>
            <option value="autres_charges_exploitation">Autres charges d'exploitation</option>
          </optgroup>
          <optgroup v-if="form.etat=='RESULTAT'" 
                    label="Autre">
            <option value="Charges_financieres_nettes">Charges financières nettes</option>
            <option value="Produits des placements">Produits des placements</option>
            <option value="autres_gains_ordinaires">Autres gains ordinaires</option>
            <option value="autres_pertes_ordinaires">Autres pertes ordinaires</option>
            <option value="impots_sur_benefices">impots sur les bénéfices</option>
            <option value="elements_extraordinaires">Eléments extraordinaires (Gains/Pertes)</option>
            <option value="Effet_modifications_comptables">Effet des modifications comptables (net d'impôt)</option>
          </optgroup>
          < !-- BILAN -- >
          <optgroup v-if="form.etat=='BILAN'" 
                    label="Actifs immobilisés">
            <option value="immobilisations_incorporelles">Immobilisations incorporelles</option>
            <option value="immobilisations_corporelles">Immobilisations corporelles</option>
            <option value="immobilisations_financieres">Immobilisations financières</option>
          </optgroup>
          <optgroup v-if="form.etat=='BILAN'" 
                    label="Actifs circulants">
            <option value="stocks">Stocks</option>
            <option value="creances">Créances</option>
            <option value="Disponibilites">Disponibilités</option>
          </optgroup>
          <optgroup v-if="form.etat=='BILAN'" 
                    label="Capitaux propres et assimilés">
            <option value="capitaux_propres">Capitaux propres</option>
          </optgroup>
          <optgroup v-if="form.etat=='BILAN'" 
                    label="Passifs circulants">
            <option value="dettes_financieres">Dettes financières</option>
            <option value="dettes_exploitation">Dettes d'exploitation</option>
            <option value="dettes_immobilisations">Dettes sur immobilisations</option>
            <option value="autres_dettes">Autres dettes</option>
          </optgroup>
          
        </select>
        <span
          v-if="form.errors().has('rubrique_etat')"
          v-text="form.errors().get('rubrique_etat')"
          class="errorMsg">
        </span>
      </div> -->

      <div class="button_form">
        <button
          type="button"
          class="btn btn-f-blue enter_btn"
          @click='submit'>
          <i class="flaticon-floppy-disk"></i> Enregistrer
        </button>
        <button
          type="reset"
          class="btn btn-f-blue cancle_btn ml-4">
          <i class="flaticon-cancel"></i> Annuler
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import form from 'vuejs-form'
import Notif from "@/components/shared/Toast"
import {mapActions,mapGetters} from "vuex"

export default {
  name: "UpdatePlan",
  components:{
    Notif
  },
  props:["mod_plan"],
  data: () => ({
    affiche:{
      ref_plan_fichier:""
    },
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    form: form({
      id:"",
      compte: "",
      description:"",
      base:"",
      flux:"",
      etat:"",
      date_inactif:"",
      categorie:"",
    })
      .rules({
        id: "required",
        compte: "required",
        description:"required",
        base:"required",
      })
      .messages({
        'libelle.libelle': 'Champ requis!',
        'id.id': 'Champ requis!'
      })
  }),
  watch: {
    // ['form.data']: {
    //   deep: true,
    //   immediate: false,
    //   handler: 'onFormChange'
    // },
    msgSuccessPlanCompte(){
      if (this.msgSuccessPlanCompte) {
        this.$emit("UpPlan",{added:true})
      }
    },
  },
  created(){
    this.form.id = this.mod_plan.id
    this.form.compte = this.mod_plan.compte
    this.form.description = this.mod_plan.description
    this.form.base = this.mod_plan.base
    this.form.flux = this.mod_plan.flux
    this.form.etat = this.mod_plan.etat
    this.form.date_inactif = this.mod_plan.date_inactif
    this.form.categorie = this.mod_plan.categorie
  },
  computed: {
    ...mapGetters(['setMsgFailPlanCompte','setMsgSuccessPlanCompte'])
  },
  methods: {
    ...mapActions(["putPlanCompte"]),
  
    submit() {
      // console.log(this.form.data)
      if (!this.form.validate().errors().any()) {
        this.putPlanCompte(this.form.data)
      }
    },
    removeSPan(element) {
      for (let afficheKey in this.affiche) {
        const valeur = this.affiche[afficheKey].split(":")[0]
        if (valeur === element) {
          this.affiche[afficheKey] = valeur
        }
      }
    },
  }
}
</script>