<template>
  <form class="form">
    <div class="form-group">
      <label for="">Libellé</label>
      <input type="text"
             class="form-control" 
             name="" 
             id=""
             v-model="$parent.updaten4Form.libelle">
    </div>
    <div class="form-group">
      <label for="">Type de Solde</label>
      <select class="form-control" 
              name="" 
              id=""
              v-model="$parent.updaten4Form.type_solde">
        <option value="CR">Total crédit</option>
        <option value="DR">Total débit</option>
        <option value="MC">Mouvements crédit</option>
        <option value="MD">Mouvements débit</option>
        <option value="SOLDE">Solde</option>
      </select>
    </div>
    <div class="form-group">
      <label for="">N° de compte associés</label>
      <v-select :options="listComptes.comptes"
                :reduce="compte => compte.compte"
                label="description"
                ref="comptes"
                id="compte"
                multiple
                placeholder="Choisir un numéro de compte comptable"
                v-model="$parent.updaten4Form.numero_compte">
      </v-select>
    </div>
  </form>
</template>
<style>
</style>
<script>
import { mapActions, mapGetters } from "vuex"
import VSelect from "vue-select"
export default({
  name:"UpdateN4",
  components:{
    VSelect
  },
  props:["niveau_4","niveau_3"],
  data:()=>({
    listComptes:{comptes:[]},
    base:""
  }),
  watch:{
    planComptes(){
      if (this.planComptes.donnees) {
        switch (this.niveau_3.base) {
          case 1:
            this.base= "SIEGE"
            break
          case 2:
            this.base= "PEAGE"
            break
          case 3:
            this.base= "PESAGE"
            break
          default:
            this.base= "AIRE_STATIONNEMENT"
            break
        }
        this.planComptes.donnees.forEach(compte => {
          if (this.base==compte.base) {
            this.listComptes.comptes.push({
              id:compte.id,
              description:compte.compte+'-'+compte.description,
              compte:compte.compte
            })  
          }
        })
        this.getIdSituation_n4(this.niveau_4)
      }
    },
    situation_id_n4(){
      if (this.situation_id_n4.situation_niveau) {
        this.$parent.updaten4Form.id = this.situation_id_n4.situation_niveau[0].id
        this.$parent.updaten4Form.libelle = this.situation_id_n4.situation_niveau[0].libelle
        this.$parent.updaten4Form.type_solde= this.situation_id_n4.situation_niveau[0].type_solde
        this.$parent.updaten4Form.numero_compte = [] 
        
        this.situation_id_n4.situation_niveau[0].numero_compte.forEach(element => {
          this.listComptes.comptes.forEach(compte => {
            if (element.compte==compte.compte) {
              this.$parent.updaten4Form.numero_compte.push(compte.compte) 
            }
          })
        })
        console.log(this.$parent.updaten4Form.numero_compte)
        this.$parent.updaten4Form.situation_niveau_3 = this.situation_id_n4.situation_niveau[0].libelle_niveau_3
        
      }
    }
  },
  created(){
    this.getListPlanComptes()
    console.log(this.niveau_3)
  },
  computed:{
    ...mapGetters(["planComptes","situation_id_n4"])
  },
  methods:{
    ...mapActions(["getListPlanComptes","getIdSituation_n4"]),
  }
})
</script>